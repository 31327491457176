<template>
    <section class="admin-section" >
        <div class="app-main" v-if="isloaded">
            <!--  Admin Side Menu Start --> 
            <SideMenu :selectedMenu="defaultActive" ref="sideMenu" />
            <!--  Admin Side Menu End -->
            <div class="app-main__outer">
                <!--  Dashbaord content start -->
                <div v-if="defaultActive === 'dashboard' & !predictTab">
                    <DashboardContent :isAdmin="IsAdmin"/>
                </div>
                <div v-if="defaultActive === 'study-center'">
                    <StudyCenter/>
                </div>
                <div v-if="defaultActive === 'free-mock-tests'">
                    <MockTests :onlyFree="'Free'"/>
                </div>
                <div v-if="defaultActive === 'scored-mock-tests'">
                    <MockTests :onlyFree="'All'"/>
                </div>
                <div v-if="defaultActive === 'vocab-books'">
                    <VocabBooks/>
                </div>
                <div v-if="defaultActive === 'settings'">
                    <Profile/>
                </div>
                <div v-if="defaultActive === 'whyPremiumPackage'">
                    <WhyPremiumPackage/>
                </div>
                <div v-if="defaultActive === 'ModuleMockTest'">
                    <ModuleMockTest/>
                </div>
                <div v-if="defaultActive === 'referral'">
                  <ReferalUsers/>
                </div>
                <div v-if="defaultActive === 'notification-center'">
                    <NotificationCenter/>
                </div>
                <div v-if="defaultActive === 'help'">
                    <Help/>
                </div>
                <div v-if="defaultActive === 'contact-us'">
                    <ContactUs/>
                </div>
                <div v-if="defaultActive === 'dashboard' & predictTab">
                  <PredictExam :type='predictType'/>
                </div>
                <div v-if="IsAdmin=='T' & defaultActive === 'register-user'">
                  <Users :users="allUsers"/>
                </div>
                <!--  Dashbaord content end -->
                <!--  Admin Footer Start -->
                <!-- Coaching Material -->
                <div v-if="defaultActive === 'listening'">
                  <CoachListening/>
                </div>
                <div v-if="defaultActive === 'writing'">
                  <CoachWriting/>
                </div>
                <div v-if="defaultActive === 'premiumTemplates'">
                  <PremiumTemplates :showOverLap="showOverLap"/>
                </div>
                <div v-if="defaultActive === 'presentation'">
                  <CoachingPresentation/>
                </div>
                <div v-if="defaultActive === 'createMeetings'">
                  <CreateMeeting/>
                </div>
                <div v-if="defaultActive === 'meetings'">
                  <MeetingList/>
                </div>
                <div v-if="defaultActive === 'coachingPlans'">
                  <CoachingPlans/>
                </div>
                <div v-if="defaultActive === 'resultCard'">
                  <UserResultCard :userResultData='null'/>
                </div>
                <div v-if="defaultActive === 'userMessages'">
                <UserMessages :usersmessages="allUsersMessages"/>
                </div>
                <div v-if="defaultActive === 'userCommentss'">
                <UserComments :userscomments="allUsersComments"/>
                </div>
                <div v-if="defaultActive === 'helpingVideos'">
                <HelpingVideos :showOverLap="showOverLap"/>
                </div>
                <div v-if="defaultActive=='referralpayments'">
                  <ReferalWithDrawRequest/>
                </div>
                <div v-if="defaultActive=='referalUser'">
                  <ReferalUsers/>
                </div>
                <div v-if="defaultActive=='userChangeNotification'">
                  <UsersChangeNotifications :usersPlansNotification="allUsersChangeNotification"/>
                </div>
                <Footer/>
                <!--  Admin Footer End -->
            </div>
            <div class="btMcontainer d-block d-lg-none">
    <!-- code here -->
    <nav class="btMmenu">
      <a @click="clickBtMenu('dashboard')" :class="currentTab == 'dashboard' ? 'btM-active':'' " class="btMmenu-item">
        <i class="pe-7s-home"></i>
        <span class="menu-item-label">Dashboard</span>
      </a>
      <a @click="clickBtMenu('study-center')" :class="currentTab == 'study-center' ? 'btM-active':'' " class="btMmenu-item">
        <i class="fa fa-university"></i>
        <span class="menu-item-label">Learning Hub</span>
      </a>
      <!-- <a @click="clickBtMenu('meetings')" :class="currentTab == 'meetings' ? 'btM-active':'' " class="btMmenu-item">
        <i class="pe-7s-plugin"></i>
        <span class="menu-item-label">Meetings</span>
      </a> -->
      <!-- Move this to toggle menu on Client request -->
      <!-- <a @click="clickBtMenu('coachingPlans')" :class="currentTab == 'coachingPlans' ? 'btM-active':'' " class="btMmenu-item" v-else>
        <i class="pe-7s-medal"></i>
        <span class="menu-item-label">Coaching Plans</span>
      </a> -->
      <a @click="clickBtMenu('settings')"  :class="currentTab == 'settings' ? 'btM-active':'' " class="btMmenu-item">
        <i class="pe-7s-config"></i>
        <span class="menu-item-label">Settings</span>
      </a>
      <a @click="clickBtMenu('contact-us')" :class="currentTab == 'contact-us' ? 'btM-active':'' " class="btMmenu-item">
        <i class="pe-7s-chat"></i>
        <span class="menu-item-label">Contact Us</span>
      </a>
    </nav>
    <!-- end of code -->
  </div>
        </div>
        <b-modal size="lg" ref="promotion-Modal" v-model="show" id="promotionModel" no-close-on-backdrop hide-footer hide-header centered>
        <b-button class="closeModalbutton" @click="$bvModal.hide('promotionModel')">X</b-button>
        <a @click="byPlans">
        <img style="display:block;width:100%;" :src="imgSrc"/> 
        <!-- <img style="display:block;width:100%;" src="@/assets/images/EID_OFFER_PREM.jpeg"/> src="@/assets/images/premiumPackage.jpeg" -->

      </a>
    </b-modal>
    <div class="page-loader" v-if="!isloaded">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div> 
    </section>
 
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import {bus} from '../../main'
import ReferalUsers from './referalUsers.vue'
// import SideMenu from '@/components/admin/SideMenu'
// import Footer from '@/components/admin/Footer'
// import DashboardContent from '@/components/admin/DashboardContent'
// import StudyCenter from '@/components/admin/StudyCenter'
// import MockTests from '@/components/admin/MockTests'
// import VocabBooks from '@/components/admin/VocabBooks'
// import ReferalUsers from '@/components/admin/referalUsers'
// import ReferalWithDrawRequest from '@/components/admin/referalWidthDraw'
// import Profile from '@/components/user/profile'
// import NotificationCenter from '@/components/admin/NotificationCenter'
// import Help from '@/components/admin/Help'
// import ContactUs from '@/components/admin/ContactUs'
// import PredictExam from '@/components/admin/predictExamView'
// import Users from '@/components/admin/AllUsers'
// import CoachListening from '@/components/coachingMaterial/coachListening'
// import CoachWriting from '@/components/coachingMaterial/coachWriting'
// import CoachingPresentation from '@/components/coachingMaterial/presentation'
// import CreateMeeting from '@/components/zoommeeting/createMeeting'
// import MeetingList from '@/components/zoommeeting/meetingList'
// import CoachingPlans from '@/components/admin/coachingPlans'
// import UserResultCard from '@/components/resultCard/userResultCard'
// import UserMessages from '@/components/admin/AllUsersMessages'
// import HelpingVideos from '@/components/admin/premiumVideo'
export default {
  props:['selectMenu'],
  data () { 
    return {
      currentTab:this.selectMenu,
      predictType:'all',
      predictTab:false,
      allUsers:null,
      allUsersMessages:null,
      allUsersComments:null,
      allUsersChangeNotification:null,
      IsAdmin:'N',
      user_type:'Free',
      showOverLap:true,
      isCoaching:'No',
      show:false,
      isloaded:true,
      imgSrc:'/audio/popupImg/'
    }
  },
  components: {
    SideMenu: () => import('@/components/admin/SideMenu'/* webpackChunkName: "SideMenu"*/),
    Footer: () => import('@/components/admin/Footer'/* webpackChunkName: "Footer"*/),
    DashboardContent: () => import('@/components/admin/DashboardContent'/* webpackChunkName: "DashboardContent"*/),
    StudyCenter: () => import('@/components/admin/StudyCenter'/* webpackChunkName: "StudyCenter"*/),
    MockTests: () => import('@/components/admin/MockTests'/* webpackChunkName: "MockTests"*/),
    VocabBooks: () => import('@/components/admin/VocabBooks'/* webpackChunkName: "VocabBooks"*/),
    PredictExam: () => import('@/components/admin/predictExamView'/* webpackChunkName: "PredictExam"*/),
    NotificationCenter: () => import('@/components/admin/NotificationCenter'/* webpackChunkName: "NotificationCenter"*/),
    Help: () => import('@/components/admin/Help'/* webpackChunkName: "Help"*/),
    ContactUs: () => import('@/components/admin/ContactUs'/* webpackChunkName: "ContactUs"*/),
    Profile: () => import('@/components/user/profile'/* webpackChunkName: "Profile"*/),
    Users: () => import('@/components/admin/AllUsers'/* webpackChunkName: "Users"*/),
    CoachListening: () => import('@/components/coachingMaterial/coachListening'/* webpackChunkName: "CoachListening"*/), 
    CoachWriting: () => import('@/components/coachingMaterial/coachWriting'/* webpackChunkName: "CoachWriting"*/),
    PremiumTemplates: () => import('@/components/coachingMaterial/premiumTemplates'/* webpackChunkName: "premiumTemplates"*/),
    CoachingPresentation: () => import('@/components/coachingMaterial/presentation'/* webpackChunkName: "CoachingPresentation"*/),
    CreateMeeting: () => import('@/components/zoommeeting/createMeeting'/* webpackChunkName: "CreateMeeting"*/),
    MeetingList: () => import('@/components/zoommeeting/meetingList'/* webpackChunkName: "MeetingList"*/),
    CoachingPlans: () => import('@/components/admin/coachingPlans'/* webpackChunkName: "CoachingPlans"*/),
    UserResultCard: () => import('@/components/resultCard/userResultCard'/* webpackChunkName: "UserResultCard"*/),
    UserMessages: () => import('@/components/admin/AllUsersMessages'/* webpackChunkName: "UserMessages"*/),
    HelpingVideos: () => import('@/components/admin/premiumVideoList'/* webpackChunkName: "HelpingVideos"*/),
    ReferalUsers: () => import('@/components/admin/referalUsers'/* webpackChunkName: "ReferalUsers"*/),
    ReferalWithDrawRequest: () => import('@/components/admin/referalWidthDraw'/* webpackChunkName: "ReferalWithDrawRequest"*/),
    UsersChangeNotifications: () => import('@/components/admin/UsersChangeNotifications'/* webpackChunkName: "UsersChangeNotifications"*/),
    WhyPremiumPackage: () => import('@/components/admin/WhyPremium'/* webpackChunkName: "UsersChangeNotifications"*/),
    ModuleMockTest: () => import('@/components/admin/ModuleMockTest'/* webpackChunkName: "UsersChangeNotifications"*/),
    UserComments:()=>import('@/components/admin/AllUsersComments')
  },
  computed: {
    defaultActive () {
      //console.log(this.currentTab)
      if(this.currentTab=='userMessages' & this.allUsersMessages==null){
      return  this.isloaded=false;
      }
      else if(this.currentTab=='userCommentss' & this.allUsersComments==null){
      return  this.isloaded=false;
      }
      else if(this.currentTab=='register-user' & this.allUsers==null){
      return  this.isloaded=false;
      }
      else if(this.currentTab=='userChangeNotification' & this.allUsersChangeNotification==null){
      return  this.isloaded=false;
      }
      else{
        this.isloaded=true;
        return this.currentTab
      }
    }
  },
  methods:{
    ...mapActions({
            fetchUsersData:'auth/fetchGraphData',
        }),
        ...mapGetters({
            GetUsersData:'auth/getPracticeNumber',
        }),
    callMenuClick(clickItem){
      this.$refs.sideMenu.activeMenu(clickItem)
    },
    byPlans(){
        console.log('byPlans');
        this.show=false
        this.$bvModal.hide('promotionModel');
        bus.$emit('menuClick', 'settings');
      },
    changeView(type){
      this.predictTab=true
      this.predictType=type
    },
    clickBtMenu(item){
      if(item=='meetings' & this.isCoaching=='No' & this.IsAdmin!='T'){
        return this.$alert("Please purchase coaching package first.","Information","warning",);
      }
      this.currentTab=item
    },
    changeMenuItem(selectedItem){
      this.currentTab=selectedItem
      this.$refs.sideMenu.changeMenuItem(selectedItem)
    },
    async fetchUsers(){
      var url='users/getall'
       this.fetchUsersData(url).then(async ()=>{
          const response=this.GetUsersData()
          this.allUsers=response.data
          this.isloaded=true;
          //console.log(this.allUsers)
          await this.fetchUsersMessages();
          await this.fetchUsersComments();
          await this.fetchUsersChangeNotifications();
      });
    },
    async fetchUsersMessages(){
      var url='users/getallUserMessages'
      //console.log(url)
       this.fetchUsersData(url).then(async ()=>{
          const response= this.GetUsersData()
          this.allUsersMessages=response.data
          var checkUnRreadMessages = this.allUsersMessages.filter((item)=>{
            return item.responseFlag==0
          });
          this.isloaded=true;
          this.$refs.sideMenu.recheckUnreadMessageCount(checkUnRreadMessages.length)
          //console.log(checkUnRreadMessages)
          //console.log(this.allUsersMessages)
      });
      },
      async fetchUsersComments(){
      var url='premiumvideo/comment/SelectAllComments'
      //console.log(url)
       this.fetchUsersData(url).then(()=>{
          const response=this.GetUsersData()
          this.allUsersComments=response.data;
          this.isloaded=true;
          if(this.allUsersComments!=null)this.allUsersComments = this.allUsersComments.filter((item)=>{
            return item.isActive==1
          });
          
          //console.log(checkUnRreadMessages)
          //console.log(this.allUsersMessages)
      })
      },
    async fetchUsersChangeNotifications(){
      var url='users/getUsersNotifications'
      //console.log(url)
       this.fetchUsersData(url).then(()=>{
        
          const response=this.GetUsersData()
          this.allUsersChangeNotification=response.data
          var checkUnRreadChangeNotifications = this.allUsersChangeNotification.filter((item)=>{
            return item.admin_notify=='F'
          });
          this.$refs.sideMenu.recheckUnreadChangeNotificationsCount(checkUnRreadChangeNotifications.length);
          this.isloaded=true;
          //console.log(checkUnRreadChangeNotifications)
          //console.log(this.allUsersChangeNotification)
      });
      }

  },
  async created(){
    this.show=false;
    const user=this.$ls.get('user')
    this.IsAdmin=user.is_admin
    this.user_type=user.user_type
    
    this.isCoaching=(user.coaching==null)?'No':user.coaching;
    debugger
    if((this.user_type=='premium' | user.ref_user_cat_id>1) & user.premium_video_allowed)
    {
      this.showOverLap=false;
    }
    else{
      this.imgSrc+='regularDeal-2024.jpeg'
        this.show=true
        this.$bvModal.show('promotionModel')
        
    }
    if(this.IsAdmin=='T'){
      this.showOverLap=false;
     await this.fetchUsers();
     
    }
    console.log(this.$ls.get('userSettings'));
    if(this.$ls.get('userSettings')!=null){
     this.byPlans(); 
     this.clickBtMenu('settings');
    }
  },
}
</script>
<style>
a, .pointer-courser{
  cursor: pointer;
}
.admin-section .tabs-row .nav-tabs .nav-link.active,
.admin-section .tabs-row .nav-tabs .nav-link:hover{
      /* background: #385076!important; */
          background: #e00403!important;
}
</style>