<template>
    <div class="app-main__inner">
  <div class="row">
    <div class="col-md-12">
        <div class="app-page-title dashboard-title">
            <div class="page-title-wrapper">
                <div class="page-title-heading">
                    <div class="page-title-icon">
                        <i class="pe-7s-share icon-gradient bg-happy-itmeo">
                        </i>
                    </div>
                    <div>Referral Users</div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div  class="row">
        <div class="col-12">
            <div class="main-card mb-3">
              <div class="card">
                <div class="card-header text-white bg-danger">
                  List of referral users
                </div>

                <div  class="card-body text-center">
                   <div class="col-12 specialPrepend mb-3">
                      <b-input-group prepend="Link">
                                  <b-form-input id="referralUrlInput" v-model="referralUrl" ></b-form-input>
                                  <b-input-group-append>
                                  <b-button type="button" class="pl-2 pr-2" id="copy" @click="copyLinkToCopyRef()">Copy</b-button>
                                  <b-button class="pl-2 pr-2" v-b-modal.modal-EmailPopup variant="success">Share</b-button>
                                  </b-input-group-append>
                              </b-input-group>
                    </div>
                  <h3 v-if="allRefUsers==null">Loading........</h3>
                  <div v-else>
            <div class="overflow-auto table-responsive ">
      <b-row >
<!-- d-none d-lg-block d-lx-block -->
      <b-col lg="6" class="my-1 mb-2">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button variant="outline-primary" :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filter On"
          description="Leave all unchecked to filter on all data"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="reg_userName">Register User</b-form-checkbox>
            <b-form-checkbox value="register_user_status">Package</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>

    </b-row>
                <b-table class="referalTable" striped hover :items="allRefUsers" :per-page="perPage"
                :current-page="currentPage" :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                >
                <!-- :disabled="data.item.register_user_status=='Free' | !data.item.ref_widthdraw" -->
                 
                 <template #cell(reg_userName)="data">
                <i>{{ data.item.reg_userName }}</i>
                </template>
                <template #cell(user_type)="data">
                <i style="text-transform:capitalize">{{ data.item.user_type }}</i>
                </template>
                <template  #cell(inserted_date)="data">
                <i>{{changeDate(data.item.inserted_date, "fullDate")}}</i>
                </template>
                <template #cell(register_user_status)="data">
                <i>{{ data.item.register_user_status }}</i>
                </template>
                <template #cell(ref_widthdraw)="data">
                  {{data.item.ref_widthdraw}}
                </template>
                </b-table>
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
            </div>
            <b-button v-if="showwithDrawOption" :disabled="!haswithdrawlOption" class="pl-4 pr-4 d-none d-lg-block d-lx-block" block variant="outline-info" @click="widthDraw()">
                     Withdraw
                   </b-button>
                </div>
                </div>
                
              </div>
            </div>
        </div>
  </div>
 
    <b-modal
      id="modalAccountDetails"
      ref="modalAccountDetails"
      title="Account Details"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      okTitle="Save & Withdraw"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Account Name"
          label-for="Account_Name">
         <b-form-input id="Account_Name" v-model="accountDetails.Account_Name"></b-form-input>
        </b-form-group>
      </form>
      <b-form-group
          label="Account Number"
          label-for="Account_Number">
         <b-form-input id="Account_Number" v-model="accountDetails.Account_Number"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Bank Name"
          label-for="Bank_Name">
         <b-form-input id="Bank_Name" v-model="accountDetails.Bank_Name"></b-form-input>
        </b-form-group>
    </b-modal>
    <b-modal
      id="modal-EmailPopup"
      ref="modal-EmailPopup"
      title="Share Referrence link"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      okTitle="Send"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Email"
          label-for="email-input"
          invalid-feedback="Email is required"
          :state="emailState"
        >
          <b-form-input
            id="email-input"
            v-model="email"
            :state="emailState"
            type="email"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
</div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
export default {
    data(){
        return{
            allRefUsers:null,
            perPage: 10,
            sortDirection: 'asc',
            currentPage: 1,
            minDate:'',
            maxDate:'',
            totalRows: 1,
            user_id:null,
            email:'',
            referralUrl: 'https://practice.pteclasses.com/?referral=',//'https://localhost:8080/?referral=',
            emailState: null,
            haswithdrawlOption:false,
            showwithDrawOption:true,
            accountDetails:{
              user_id:0,
              Account_Name:'',
              Account_Number:'',
              Bank_Name:'',
              ref_widthdraw:'',
              ref_widthdraw_date:'',
              url:'users/updateAccountDetails'
            },
            filter: null,
            filterOn: [],
            fields: [
          {
            key: 'reg_userName',
            label: 'Registered User',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'inserted_date',
            label: 'Registered Date',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'register_user_status',
            label: 'Current Package',
            sortable: true,
            variant: 'info'
          }
          ,{
            key: 'ref_widthdraw',
            label: 'Payment Status',
            sortable: false,
            variant: 'success'
          }
        ],
        }
    },
    methods:{
        ...mapActions({
            getData: 'speakmodule/fetchQuestion',
            saveData: 'speakmodule/saveAnswer',
        }),
        ...mapGetters({
            getDataResponse: 'speakmodule/questions',
            dataResponse: 'speakmodule/AnswerResponse',
        }),
        copyLinkToCopyRef(){
            /* Get the text field */
            var copyText = document.getElementById("referralUrlInput");
            //console.log(copyText)
            /* Select the text field */
            copyText.select();
            /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand("copy");
        },
        copyLinkToShare(){
            var data={
                referralLink:this.referralUrl,
                email:this.email,
                url:'users/sendReferralEmail'
            }
           // alert('test')
            this.sendEmail(data).then(()=>{
                const response=this.getResponse()
                //console.log(response)
                if(response.success==1){
                    this.$alert(response.message)
                }
            })
        },
        checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.emailState = valid
        if(valid){
            this.emailState= this.emailvalidation()
            this.emailState=valid
        }
        return valid
      },
      emailvalidation: function (){
                    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm
                    const email = this.email
                   // ////console.log(email)
                    if(!re.test(email))
                    {
                        return false
                    }
                    else return true
        },
        changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("Do MMMM YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("D-MM-YYYY");
          } 
          else if(dateType === "saveDate"){
             return moment(date1).format("YYYY-MM-D 12:00:00");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        resetModal(){
          this.accountDetails={
              user_id:0,
              Account_Name:'',
              Account_Number:'',
              Bank_Name:'',
              ref_widthdraw:'Request Submitted',
              ref_widthdraw_date:'',
              url:'users/updateAccountDetails'
            }
        },
        handleOk(){
          if(this.accountDetails.Account_Name=='' | this.accountDetails.Account_Name=='' | this.accountDetails.Bank_Name==''){
            alert('All account fields are required')
          }
          else{
            const currentDate=new Date()
            this.accountDetails.ref_widthdraw='Request Submitted'
            this.accountDetails.ref_widthdraw_date=this.changeDate(currentDate,'saveDate')
            this.accountDetails.user_id=this.$ls.get('user').user_id
            this.saveData(this.accountDetails).then(()=>{
              const res=this.dataResponse()
              //console.log(res)
              if(res.success==1)
              {
                this.haswithdrawlOption=false
                this.$alert(res.message)
                this.fetchReferalUsers()
              }
            })
          }
        },
        fetchReferalUsers(){
          if(this.$ls.get('user').ref_user_cat_id>1){
            this.showwithDrawOption=false;
          }
          else{
            this.getData('users/getRefall').then(()=>{
                var refUsers=this.getDataResponse()
                const userId=this.$ls.get('user').user_id
                if(refUsers.success)
                {
                    // this.allRefUsers=r
                    //console.log(refUsers.data)
                    this.allRefUsers=refUsers.data.filter((item)=>{
                      return item.reference_user_id==userId
                    })
                    const anyWithdrawl=this.allRefUsers.filter((item)=>{
                      return item.register_user_status!='Free' & item.ref_widthdraw==''
                    })
                    if(anyWithdrawl!=null)
                    {
                      if(anyWithdrawl.length>0){
                        this.haswithdrawlOption=true
                      }
                    }
                }
            });
          }
          
        },
        fetchAccountDetails(){
          const userId=this.$ls.get('user').user_id
          this.getData('users/userAccountDetail='+userId).then(()=>{
            const accRes=this.getDataResponse()
            if(accRes.success==1)
              {
                if(accRes.data!=null){
                  this.accountDetails.user_id=userId
                this.accountDetails.Account_Name=accRes.data.Account_Name
                this.accountDetails.Account_Number=accRes.data.Account_Number
                this.accountDetails.Bank_Name=accRes.data.Bank_Name
                }
                
              }
          })
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      widthDraw(){
        // var selectedReferral=this.allRefUsers.filter((item)=>{
        //   return item.referal_id==referral_id
        // })[0]
        ////debugger
        const currentDate=new Date()
        ////console.log(selectedReferral)
        if(this.accountDetails.Account_Name=='' | this.accountDetails.Account_Number==''){
          return this.$refs['modalAccountDetails'].show()
        }
        else{
          var data={
            url:'users/updateWithDrawInfo',
            ref_widthdraw:'Request Submitted',
            ref_widthdraw_date: this.changeDate(currentDate,'saveDate')
          }
          this.saveData(data).then(()=>{
            const res=this.dataResponse()
              //console.log(res)
              if(res.success==1)
              {
                this.haswithdrawlOption=false
                this.$alert(res.message)
                this.fetchReferalUsers()
              }
          })
        }
      }
    },
    computed: {
      rows() {
        return this.allRefUsers.length
      }
    },
    beforeMount(){
      this.user_id=this.$ls.get('user').user_id
        this.email=this.$ls.get('user').email_id
        const encryptValue='diresu'+this.user_id
        let encryptedUserId=Math.random().toString(36).slice(-10)+'1-0'+encryptValue+'1-0'+Math.random().toString(36).slice(-10)+Math.random().toString(36).slice(-10);
        // let encryptedUserId = this.CryptoJS.AES.encrypt(JSON.stringify({ encryptValue }), "Think higher").toString()
        // encryptedUserId=encryptedUserId.replaceAll('/','-A12-')
        // encryptedUserId=encryptedUserId.replaceAll('\\','-B12-')
       // const encryptedEmail = this.CryptoJS.AES.encrypt(this.email, "Think higher").toString()
        //console.log(encryptedUserId)
        ////console.log(encryptedEmail)
        this.referralUrl+=encryptedUserId//+'-'+encryptedEmail;
        //console.log(this.referralUrl)
        this.fetchReferalUsers()
        this.fetchAccountDetails()
    }
}
</script>
<style scoped>
.specialPrepend div.input-group-prepend
{
    padding: 0px!important;
}
.specialPrepend .input-group input
{
    max-width: 500px!important;
}
.specialPrepend .input-group button{
    min-width: 100px!important;
        margin: 0px!important;
}
.specialPrepend #copy 
{
    background: #273045;
    border: #273045 1px solid;
    color: #fff!important;
}
.specialPrepend #copy:hover, .specialPrepend #copy:focus, .specialPrepend #copy:visited
{
    background: #000000;
    border: #000 1px solid;
    color: #fff!important;
}


</style>

<style>

@media 
only screen and (max-width: 760px) {

	/* Force table to not be like tables anymore */
	table.referalTable,table.referalTable thead,table.referalTable tbody,table.referalTable th,table.referalTable td,table.referalTable tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	table.referalTable thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	table.referalTable tr { border: 1px solid #ccc; }
	
	table.referalTable td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	table.referalTable td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	table.referalTable td:nth-of-type(1):before { content: "Registered User"; }
	table.referalTable td:nth-of-type(2):before { content: "Registered Date"; }
	table.referalTable td:nth-of-type(3):before { content: "Current Package"; }
	table.referalTable td:nth-of-type(4):before { content: "Payment Status"; }
	/* td:nth-of-type(5):before { content: "Wars of Trek?"; }
	td:nth-of-type(6):before { content: "Secret Alias"; }
	td:nth-of-type(7):before { content: "Date of Birth"; }
	td:nth-of-type(8):before { content: "Dream Vacation City"; }
	td:nth-of-type(9):before { content: "GPA"; }
	td:nth-of-type(10):before { content: "Arbitrary Data"; } */
}

</style>